<template>
  <div class="upload-file">
    <b-modal
      id="repInfoRequestDetailModal"
      centered
      size="md"
      no-close-on-backdrop
      hide-footer
      v-if="row"
    >
      <template #modal-header>
        <div class="repInfoRequestDetailModal-header text-center">
          <h4>Information Change Request Detail</h4>
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="repInfoRequestDetailModal-form">
          <div class="form-group row">
            <div class="col-lg-6 mb-3">
              <label>Type</label>
              <input type="text" :value="row.type_name" disabled class="custom-field">
            </div>
            <div class="col-lg-6 mb-3">
              <label>Status</label>
            <input type="text" :value="row.status_name" disabled class="custom-field">
            </div>
          </div>
          <div class="form-group mb-3">
            <label>Member</label>
            <input type="text" :value="`${row.user_info.name} - ${row.policy_id}`" disabled class="custom-field">
          </div>
          <div class="form-group mb-3">
            <label>Information</label>
            <input type="text" :value="row.information" disabled class="custom-field">
          </div>
          <div class="form-group mb-3">
            <label>Reason</label>
            <textarea type="text" :value="row.admin_reason" disabled class="custom-field">
            </textarea>
          </div>
          <!-- <div class="form-group mb-3">
            <button class="univ-btnEdit">Edit Info</button>
          </div> -->

        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "RepInfoRequestDetailModal",
  props: {
    row: {
      required: true,
    },
  },
  methods: {
    reset() {
      this.$emit("update:row", null);
    },
    closeModal() {
      this.$bvModal.hide("repInfoRequestDetailModal");
      this.reset();
    },
  },
};
</script>

<style>
.repInfoRequestDetailModal-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
}
.repInfoRequestDetailModal-header p {
  font-size: 14px;
  color: #2c3f58;
}
.repInfoRequestDetailModal-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.repInfoRequestDetailModal-form label{
  display: block;
  color: #5e5e5e;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
}
.repInfoRequestDetailModal-form .custom-field{
      width: 100%;
    border: 1px solid #dce1e7;
    outline: none;
    border-radius: 6px;
    padding: 9px 15px;
    font-size: 14px;
    transition: all ease 0.6s;
}
.univ-btnEdit, .univ-btnEdit:focus{
  background: linear-gradient(#0cf, #1597b8 49%, #0567bc);
    border-radius: 6px;
    color: #fff;
    outline: none;
    border: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    margin-top: 10px;
    transition: all ease 0.6s;
    box-shadow: inherit;
}
.modal-content{
    border: 0;
    border-radius: 15px;
}
.modal-header{
    justify-content: center;
    border: 0;
    padding: 25px 0;
    position: relative;
}
</style>
