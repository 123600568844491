<template>
  <tbody>
    <tr v-if="!rows.records || rows.records.length < 1">
      <td :colspan="colSpan" align="center">No records found.</td>
    </tr>
    <tr v-for="(row, index) in rows.records" :key="index">
      <td>
        <router-link
          :to="{
            name: 'MemberPolicyDetail',
            params: { policyId: encodedId(row.policy_id) },
          }"
          target="_blank"
        >
          <span class="brand-color">
            {{ row.policy_id }}
          </span>
        </router-link>
        <br />
        <span class="member" v-if="row.user_info">
          {{ row.user_info.name || "" }}
        </span>
      </td>
      <td>
        {{ row.type_name }}
      </td>
      <td>
        {{ row.information | truncate(100) }}
      </td>
      <td>
        {{ row.status_name }}
        <br />
        <template
          v-if="
            row.status == 'completed' ||
            row.status == 'rejected' ||
            row.status == 'approved' ||
            row.status == 'canceled'
          "
        >
          <span class="updated-at">{{ row.updated_at }}</span>
        </template>
      </td>
      <td>
        {{ row.created_at }}
      </td>
      <td>
        <div class="action-icons">
          <custom-button
            v-if="row.document"
            title="Download Document"
            iconClass="fas fa-download"
            @onClicked="downloadDocument(row.document)"
          />
          <custom-button
            title="Detail"
            iconClass="fas fa-eye"
            @onClicked="openModal(row, 'repInfoRequestDetailModal')"
          />
          <template v-if="row.status == 'pending'">
            <custom-button
              title="Cancel Request"
              iconClass="fas fa-times"
              @onClicked="openModal(row, 'repInfoRequestCancelModal')"
            />
          </template>
        </div>
      </td>
    </tr>
  </tbody>
</template>
<script>
export default {
  props: {
    rows: {
      required: true,
    },
    colSpan: {
      type: Number,
      required: true,
    },
  },
  methods: {
    downloadDocument(url) {
      const newUrl = process.env.VUE_APP_FILE_DOWNLOAD_URL+url;
      window.open(newUrl, "_blank");
    },
    encodedId(id) {
      return btoa(id);
    },
    openModal(row, type) {
      this.row = row;
      this.$emit("onRowOpen", row, type);
    },
  },
};
</script>
<style scoped>
.updated-at{
  font-size: 11px;
}
</style>