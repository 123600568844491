var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tbody', [!_vm.rows.records || _vm.rows.records.length < 1 ? _c('tr', [_c('td', {
    attrs: {
      "colspan": _vm.colSpan,
      "align": "center"
    }
  }, [_vm._v("No records found.")])]) : _vm._e(), _vm._l(_vm.rows.records, function (row, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'MemberPolicyDetail',
          params: {
            policyId: _vm.encodedId(row.policy_id)
          }
        },
        "target": "_blank"
      }
    }, [_c('span', {
      staticClass: "brand-color"
    }, [_vm._v(" " + _vm._s(row.policy_id) + " ")])]), _c('br'), row.user_info ? _c('span', {
      staticClass: "member"
    }, [_vm._v(" " + _vm._s(row.user_info.name || "") + " ")]) : _vm._e()], 1), _c('td', [_vm._v(" " + _vm._s(row.type_name) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("truncate")(row.information, 100)) + " ")]), _c('td', [_vm._v(" " + _vm._s(row.status_name) + " "), _c('br'), row.status == 'completed' || row.status == 'rejected' || row.status == 'approved' || row.status == 'canceled' ? [_c('span', {
      staticClass: "updated-at"
    }, [_vm._v(_vm._s(row.updated_at))])] : _vm._e()], 2), _c('td', [_vm._v(" " + _vm._s(row.created_at) + " ")]), _c('td', [_c('div', {
      staticClass: "action-icons"
    }, [row.document ? _c('custom-button', {
      attrs: {
        "title": "Download Document",
        "iconClass": "fas fa-download"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.downloadDocument(row.document);
        }
      }
    }) : _vm._e(), _c('custom-button', {
      attrs: {
        "title": "Detail",
        "iconClass": "fas fa-eye"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(row, 'repInfoRequestDetailModal');
        }
      }
    }), row.status == 'pending' ? [_c('custom-button', {
      attrs: {
        "title": "Cancel Request",
        "iconClass": "fas fa-times"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openModal(row, 'repInfoRequestCancelModal');
        }
      }
    })] : _vm._e()], 2)])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }