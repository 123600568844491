<template>
  <div class="upload-file">
    <b-modal
      id="repInfoRequestForm"
      centered
      size="md"
      hide-footer
      no-close-on-backdrop
      @shown="getOptions"
    >
      <template #modal-header>
        <div class="repInfoRequestForm-header text-center">
          <h4>{{ !row ? "Add" : "" }} Information Request</h4>

          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="repInfoRequestForm-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.stop.prevent="handleSubmit(createRequest)"
              ref="repInfoRequestContainer"
            >
              <input-multi-select
                :vmodel.sync="form.type"
                labelName="Type"
                :options="types.map((type) => type.value)"
                :custom-label="(opt) => types.find((x) => x.value == opt).name"
                :rules="{ required: true }"
                formGroupClass="form-group mb-3"
                placeholder="Select Type"
                :is-disable="row ? true : false"
              />

              <input-multi-select
                :vmodel.sync="form.policy_id"
                labelName="Member - Policy"
                :options="members.map((type) => type.policy_id)"
                :custom-label="
                  (opt) =>
                    members.find((x) => x.policy_id == opt).formatted_name
                "
                :rules="{ required: true }"
                formGroupClass="form-group mb-3"
                placeholder="Select Member - Policy"
                @onSearchChange="getMembers"
                :is-loading="isLoading"
                :is-disable="row ? true : false"
              />

              <input-file
                :rules="{ required: false }"
                labelFor="document"
                labelName="Select Document "
                inputName="document"
                :vmodel.sync="form.document"
                :apiErrors="apiErrors"
                formGroupClass="form-group mb-3"
                fieldName="document"
              />

              <input-textarea
                :rules="{ required: true }"
                labelFor="information"
                labelName="Information"
                inputName="information"
                :vmodel.sync="form.information"
                formGroupClass="form-group mb-3"
                :readonly="row ? true : false"
              />

              <div class="col-md-12" v-if="!row">
                <b-button
                  class="univ-btnEdit"
                  type="submit"
                  :disabled="isCreate"
                >
                  <template>
                    {{ isCreate ? "Sending Request ..." : "Send Request" }}
                  </template>
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import Api from "@/resource/Api";
const formData = {
  type: "",
  information: "",
  policy_id: "",
  document: "",
};
export default {
  name: "repInfoRequestForm",
  props: {
    row: {
      default: null,
    },
  },
  data() {
    return {
      form: { ...formData },
      isCreate: false,
      isLoading: false,
      types: [],
      members: [],
      apiErrors: null,
    };
  },
  methods: {
    getMembers(query) {
      let url = "v2/rep-info-request/get-members";
      if (query && query.length > 2) {
        this.isLoading = true;
        url = "v2/rep-info-request/get-members?query=" + query;
      }
      Api.get(url)
        .then((response) => {
          this.members = response.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /**
     * calling in options api to load in multi selcect form
     */
    getOptions() {
      let loader = this.$loading.show();
      Api.get("v2/rep-info-request/get-options")
        .then((response) => {
          this.types = response.data.data.types;
          this.$nextTick(() => {
            if (this.row) {
              const { type, policy_id, information } = { ...this.row };
              this.form = {
                type,
                information,
                policy_id: policy_id.toString(),
              };
              this.$nextTick(() => {
                this.getMembers(policy_id.toString());
              });
            } else {
              this.form = { ...formData };
              this.getMembers();
            }
          });
        })
        .finally(() => {
          loader.hide();
        });
    },
    reset() {
      this.form = { ...formData };
    },
    closeModal() {
      this.$emit("update:row", null);
      this.$bvModal.hide("repInfoRequestForm");
      this.reset();
    },
    errorResponse(error) {
      if (error.response.status == 422) {
        this.apiErrors = { ...error.response.data.errors };
        setTimeout(() => {
          this.apiErrors = null;
        }, 5000);
      } else {
        this.$notify(error.response.data.message, "error");
      }
    },
    async createRequest() {
      let url;
      this.isCreate = true;
      url = `v2/rep-info-request/store`;
      let loader = this.$loading.show({
        container: this.$refs.createRequestContainer,
      });
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      await Api.post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
          this.$emit("onSuccess");
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.apiErrors = error.response.data.errors;
            // setTimeout(() => {
            //   this.apiErrors = null;
            // }, 5000);
          } else {
            this.$notify(error.response.data.message, "error");
          }
        })
        .finally(() => {
          this.isCreate = false;
          setTimeout(() => {
            loader.hide();
          }, 2000);
        });
    },
  },
};
</script>

<style>
.repInfoRequestForm-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
}
.repInfoRequestForm-header p {
  font-size: 14px;
  color: #2c3f58;
}
.repInfoRequestForm-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.repInfoRequestForm-form label {
  display: block;
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}
</style>
