var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('simple-page', {
    scopedSlots: _vm._u([{
      key: "breadcrumb",
      fn: function fn() {
        return [_c('breadcrumb', {
          attrs: {
            "items": _vm.breadcrumbItems
          }
        })];
      },
      proxy: true
    }, {
      key: "pageTitle",
      fn: function fn() {
        return [_c('div', {
          staticClass: "infoRep-titleBtn"
        }, [_c('a', {
          staticClass: "infoadd-Btn",
          attrs: {
            "href": "javascript:void(0);"
          },
          on: {
            "click": function click($event) {
              _vm.$bvModal.show('repInfoRequestForm');

              _vm.row = null;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v(" New Request")])])];
      },
      proxy: true
    }, {
      key: "topPagination",
      fn: function fn() {
        return [_c('div', {
          staticClass: "top-pagination mb-3"
        }, [_c('pagination', {
          attrs: {
            "rows": _vm.rows,
            "filters": []
          },
          on: {
            "update:rows": function updateRows($event) {
              _vm.rows = $event;
            }
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('simple-table', {
          attrs: {
            "columns": _vm.columns
          },
          scopedSlots: _vm._u([{
            key: "tableBody",
            fn: function fn() {
              return [_c('rep-info-request-table', {
                attrs: {
                  "rows": _vm.rows,
                  "colSpan": _vm.columns.length
                },
                on: {
                  "update:rows": function updateRows($event) {
                    _vm.rows = $event;
                  },
                  "onRowOpen": _vm.openRowOpenModal
                }
              })];
            },
            proxy: true
          }])
        }), _c('pagination', {
          attrs: {
            "rows": _vm.rows,
            "filters": []
          },
          on: {
            "update:rows": function updateRows($event) {
              _vm.rows = $event;
            }
          }
        })];
      },
      proxy: true
    }])
  }), _c('rep-info-request-form', {
    attrs: {
      "row": _vm.row
    },
    on: {
      "onSuccess": _vm.getAll,
      "update:row": function updateRow($event) {
        _vm.row = $event;
      }
    }
  }), _c('rep-info-request-cancel-modal', {
    attrs: {
      "row": _vm.row
    },
    on: {
      "update:row": function updateRow($event) {
        _vm.row = $event;
      },
      "onSuccess": _vm.getAll
    }
  }), _c('rep-info-request-detail-modal', {
    attrs: {
      "row": _vm.row
    },
    on: {
      "onSuccess": _vm.getAll,
      "update:row": function updateRow($event) {
        _vm.row = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }