var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "upload-file"
  }, [_c('b-modal', {
    attrs: {
      "id": "repInfoRequestForm",
      "centered": "",
      "size": "md",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "shown": _vm.getOptions
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "repInfoRequestForm-header text-center"
        }, [_c('h4', [_vm._v(_vm._s(!_vm.row ? "Add" : "") + " Information Request")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "repInfoRequestForm-form"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "repInfoRequestContainer",
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.createRequest);
            }
          }
        }, [_c('input-multi-select', {
          attrs: {
            "vmodel": _vm.form.type,
            "labelName": "Type",
            "options": _vm.types.map(function (type) {
              return type.value;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.types.find(function (x) {
                return x.value == opt;
              }).name;
            },
            "rules": {
              required: true
            },
            "formGroupClass": "form-group mb-3",
            "placeholder": "Select Type",
            "is-disable": _vm.row ? true : false
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "type", $event);
            }
          }
        }), _c('input-multi-select', {
          attrs: {
            "vmodel": _vm.form.policy_id,
            "labelName": "Member - Policy",
            "options": _vm.members.map(function (type) {
              return type.policy_id;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.members.find(function (x) {
                return x.policy_id == opt;
              }).formatted_name;
            },
            "rules": {
              required: true
            },
            "formGroupClass": "form-group mb-3",
            "placeholder": "Select Member - Policy",
            "is-loading": _vm.isLoading,
            "is-disable": _vm.row ? true : false
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "policy_id", $event);
            },
            "onSearchChange": _vm.getMembers
          }
        }), _c('input-file', {
          attrs: {
            "rules": {
              required: false
            },
            "labelFor": "document",
            "labelName": "Select Document ",
            "inputName": "document",
            "vmodel": _vm.form.document,
            "apiErrors": _vm.apiErrors,
            "formGroupClass": "form-group mb-3",
            "fieldName": "document"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "document", $event);
            }
          }
        }), _c('input-textarea', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "information",
            "labelName": "Information",
            "inputName": "information",
            "vmodel": _vm.form.information,
            "formGroupClass": "form-group mb-3",
            "readonly": _vm.row ? true : false
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "information", $event);
            }
          }
        }), !_vm.row ? _c('div', {
          staticClass: "col-md-12"
        }, [_c('b-button', {
          staticClass: "univ-btnEdit",
          attrs: {
            "type": "submit",
            "disabled": _vm.isCreate
          }
        }, [[_vm._v(" " + _vm._s(_vm.isCreate ? "Sending Request ..." : "Send Request") + " ")]], 2)], 1) : _vm._e()], 1)];
      }
    }])
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }