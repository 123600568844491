var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "upload-file"
  }, [_vm.row ? _c('b-modal', {
    attrs: {
      "id": "repInfoRequestDetailModal",
      "centered": "",
      "size": "md",
      "no-close-on-backdrop": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "repInfoRequestDetailModal-header text-center"
        }, [_c('h4', [_vm._v("Information Change Request Detail")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }], null, false, 341256657)
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "repInfoRequestDetailModal-form"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-lg-6 mb-3"
  }, [_c('label', [_vm._v("Type")]), _c('input', {
    staticClass: "custom-field",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.row.type_name
    }
  })]), _c('div', {
    staticClass: "col-lg-6 mb-3"
  }, [_c('label', [_vm._v("Status")]), _c('input', {
    staticClass: "custom-field",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.row.status_name
    }
  })])]), _c('div', {
    staticClass: "form-group mb-3"
  }, [_c('label', [_vm._v("Member")]), _c('input', {
    staticClass: "custom-field",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": "".concat(_vm.row.user_info.name, " - ").concat(_vm.row.policy_id)
    }
  })]), _c('div', {
    staticClass: "form-group mb-3"
  }, [_c('label', [_vm._v("Information")]), _c('input', {
    staticClass: "custom-field",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.row.information
    }
  })]), _c('div', {
    staticClass: "form-group mb-3"
  }, [_c('label', [_vm._v("Reason")]), _c('textarea', {
    staticClass: "custom-field",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.row.admin_reason
    }
  })])])])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }