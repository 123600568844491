<template>
  <div>
    <simple-page>
      <template v-slot:breadcrumb>
        <breadcrumb :items="breadcrumbItems" />
      </template>
      <template v-slot:pageTitle>
        <div class="infoRep-titleBtn">
          <a
            href="javascript:void(0);"
            class="infoadd-Btn"
            @click="
              $bvModal.show('repInfoRequestForm');
              row = null;
            "
            ><i class="fa fa-plus" aria-hidden="true"></i> New Request</a
          >
        </div>
      </template>
      <template v-slot:topPagination>
        <div class="top-pagination mb-3">
          <pagination :rows.sync="rows" :filters="[]" />
        </div>
      </template>
      <template v-slot:table>
        <simple-table :columns="columns">
          <template v-slot:tableBody>
            <rep-info-request-table
              :rows.sync="rows"
              :colSpan="columns.length"
              @onRowOpen="openRowOpenModal"
            />
          </template>
        </simple-table>
        <pagination :rows.sync="rows" :filters="[]" />
      </template>
    </simple-page>
    <rep-info-request-form @onSuccess="getAll" :row.sync="row" />
    <rep-info-request-cancel-modal :row.sync="row" @onSuccess="getAll" />
    <rep-info-request-detail-modal @onSuccess="getAll" :row.sync="row" />
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import RepInfoRequestTable from "@/views/RepInfoRequest/partials/RepInfoRequestTable.vue";
import RepInfoRequestForm from "@/views/RepInfoRequest/RepInfoRequestForm.vue";
import RepInfoRequestCancelModal from "./partials/RepInfoRequestCancelModal.vue";
import RepInfoRequestDetailModal from "./partials/RepInfoRequestDetailModal.vue";
export default {
  name: "RepInfoRequest",
  components: {
    RepInfoRequestTable,
    RepInfoRequestForm,
    RepInfoRequestCancelModal,
    RepInfoRequestDetailModal,
  },
  data: () => ({
    rows: [],
    columns: [
      { label: "System ID" },
      { label: "Type" },
      { label: "Information" },
      { label: "Status" },
      { label: "Created At" },
      { label: "Action" },
    ],
    row: null,
    breadcrumbItems: [{ title: "Rep Information Requests", active: true }],
  }),
  methods: {
    openRowOpenModal(row, type) {
      this.row = row;
      this.$nextTick(() => {
        this.$bvModal.show(type);
      });
    },
    getAll(filters = null) {
      let url;
      let loader = this.$loading.show();
      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `v2/rep-info-request/list?${filterUrl}`;
      } else {
        url = `v2/rep-info-request/list`;
      }

      Api.get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {
          this.rows = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.getAll();
  },
};
</script>
<style>
.infoRep-titleBtn {
  display: flex;
  align-items: center;
}
.infoRep-titleBtn h1 {
  margin-bottom: 0;
  margin-right: 15px;
}
.infoRep-titleBtn .infoadd-Btn {
  background: linear-gradient(#0cf, #1597b8 49%, #0567bc);
  color: #fff;
  border: 0;
  border-radius: 6px;
  font-size: 13px;
  padding: 8px 30px;
  outline: none;
}
.brand-color {
  color: #29bcff;
  font-weight: 600;
}
</style>
