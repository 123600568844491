<template>
  <div class="upload-file">
    <b-modal
      id="repInfoRequestCancelModal"
      centered
      size="md"
      hide-footer
      no-close-on-backdrop
      @shown="getData"
    >
      <template #modal-header>
        <div class="repInfoRequestCancelModal-header text-center">
          <h4>Cancel Request</h4>

          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="repInfoRequestCancelModal-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.stop.prevent="handleSubmit(cancelRequest)"
              ref="repInfoRequestContainer"
            >

              <input-textarea
                :rules="{ required: false }"
                labelFor="reason"
                labelName="Reason"
                inputName="reason"
                :vmodel.sync="form.cancel_reason"
                formGroupClass="form-group mb-3"
              />

              <div class="col-md-12">
                <b-button
                  class="univ-btnEdit"
                  type="submit"
                  :disabled="isCancel"
                >
                  <template>
                    {{ isCancel ? "Canceling Request ..." : "Cancel Request" }}
                  </template>
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import Api from "@/resource/Api";
const formData = {
  id: "",
  cancel_reason: "",
};
export default {
  name: "RepInfoRequestCancelModal",
  data() {
    return {
      form: { ...formData },
      isCancel: false,
      isLoading:false,
      types: [],
      members: [],
    };
  },
  props:{
      row:{
        required:true
      }
  },
  methods: {
    getData() {
        this.form.id = this.row.id
    },
    reset() {
        this.$emit("update:row",null)
      this.form = { ...formData };
      this.$refs.observer.reset();
    },
    closeModal() {
      this.$bvModal.hide("repInfoRequestCancelModal");
      this.reset();
    },
    cancelRequest() {
      let url;
      this.isCancel = true;
      url = `v2/rep-info-request/cancel-request`;
      let loader = this.$loading.show({
        container: this.$refs.cancelRequestContainer,
      });
      Api.put(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
          this.$emit("onSuccess");
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isCancel = false;
          setTimeout(() => {
            loader.hide();
          }, 2000);
        });
    },
  },
};
</script>

<style>
.repInfoRequestCancelModal-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
}
.repInfoRequestCancelModal-header p {
  font-size: 14px;
  color: #2c3f58;
}
.repInfoRequestCancelModal-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.univ-btnEdit, .univ-btnEdit:focus{
  background: linear-gradient(#0cf, #1597b8 49%, #0567bc);
    border-radius: 6px;
    color: #fff;
    outline: none;
    border: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    margin-top: 10px;
    transition: all ease 0.6s;
    box-shadow: inherit;
}
.repInfoRequestCancelModal-form label{
    display: block;
  color: #5e5e5e;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
}
</style>
